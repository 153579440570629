@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700&family=Roboto&display=swap');

.sidebar {
  position: fixed; 
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: #3e3e3e;
  border-right: 2px solid #d4af37;
  color: #d4af37;
  padding: 20px;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto', sans-serif;
  transform: translateX(-100%); /* Hidden by default for mobile */
  transition: transform 0.3s ease;
  z-index: 999;
}

.sidebar.open {
  transform: translateX(0);
}

/* Desktop screens: always show the sidebar */
@media (min-width: 768px) {
  .sidebar {
    transform: translateX(0);
    position: static;
    width: 200px;
    height: auto;
  }
}

.sidebar h2 {
  font-family: 'Cinzel', serif;
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
}

.sidebar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.sidebar ul li {
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  border-radius: 5px;
  margin-bottom: 5px;
}

.sidebar ul li:hover {
  background-color: #4b3c2b;
  color: #fff;
}

.sidebar ul li.active {
  background-color: #5c4d3f;
  color: #fff;
  font-weight: bold;
}
