.admin-panel {
  padding: 20px;
  color: #eaeaea;
  /* your styling... */
}

.admin-panel h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #d4af37;
}

.admin-panel table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.admin-panel th, .admin-panel td {
  border: 1px solid #444;
  padding: 8px 12px;
  text-align: left;
}

.admin-panel th {
  background-color: #333;
  color: #d4af37;
}

.admin-panel tr:nth-child(even) {
  background-color: #2a2a2a;
}

.admin-panel button {
  background-color: #6441a5;
  color: #fff;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
}

.admin-panel button:hover {
  background-color: #7d5abd;
}
