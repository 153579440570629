@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700&family=Roboto&display=swap');

.home-tab {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

.home-tab h3 {
  font-family: 'Cinzel', serif;
  font-size: 1.8rem;
  color: #d4af37;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  margin-bottom: 1rem;
}

.home-tab p {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #eaeaea;
}

.toggle-bot-button,
.start-bot-button,
.stop-bot-button {
  font-family: 'Roboto', sans-serif;
  background-color: #6441a5;
  color: #fff;
  padding: 12px 25px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin: 0.5rem;
  transition: background-color 0.3s;
}

.toggle-bot-button:hover,
.start-bot-button:hover,
.stop-bot-button:hover {
  background-color: #7d5abd;
}

.toggle-bot-button:disabled,
.start-bot-button:disabled,
.stop-bot-button:disabled {
  background-color: #5f5f5f;
  cursor: not-allowed;
}
