@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700&family=Roboto&display=swap');

.bot-settings-tab {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  color: #eaeaea;
}

.bot-settings-tab h3 {
  font-family: 'Cinzel', serif;
  font-size: 1.8rem;
  color: #d4af37;
  margin-bottom: 0.5rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

.bot-settings-tab h4 {
  font-family: 'Cinzel', serif;
  font-size: 1.3rem;
  color: #fff;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.personality-info {
  margin-bottom: 1rem;
  color: #ccc;
  line-height: 1.3;
}

.example-text {
  display: block;
  font-style: italic;
  color: #d4af37;
}

.personality-textarea {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 10px;
  border: 1px solid #d4af37;
  border-radius: 5px;
  background-color: #2a2a2a;
  color: #fff;
  resize: vertical;
}

/* A note describing each section, just to be consistent with style */
.section-note {
  font-size: 0.95rem;
  color: #ccc;
  margin-bottom: 0.5rem;
}

.event-settings label,
.command-settings label {
  display: block;
  margin-bottom: 8px;
  cursor: pointer;
}

.bot-settings-tab input[type="checkbox"] {
  margin-right: 8px;
}

.save-button {
  font-family: 'Roboto', sans-serif;
  background-color: #6441a5;
  color: #fff;
  padding: 12px 25px;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.save-button:hover {
  background-color: #7d5abd;
}
