@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700&family=Roboto&display=swap');

.dashboard-container {
  display: flex;
  background-image: url('../../assets/background.jpg'); /* Adjust path as needed */
  min-height: 100vh;
  background-color: #2a2a2a; /* Plain dark background */
  color: #fff;
  font-family: 'Roboto', sans-serif;
  position: relative;
}

.dashboard-content {
  flex: 1;
  padding: 20px;
  overflow: auto;
  /* If you prefer a background image behind the content:
     background-image: url('/assets/tavern-background.jpg');
     background-size: cover;
     background-position: center; 
     ... */
}

/* Hamburger icon for mobile */
.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000; /* Above the sidebar when closed */
}

.hamburger-icon > div {
  width: 100%;
  height: 4px;
  background-color: #fff;
}

/* Hide hamburger on desktop */
@media (min-width: 768px) {
  .hamburger-icon {
    display: none;
  }
}
