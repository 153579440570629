/* 
  Import two fonts:
  1) Cinzel (for headings, medieval vibe)
  2) Roboto (for body text, simpler and more readable)
*/
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700&family=Roboto&display=swap');

/* Reset default browser margins/padding to avoid any white borders */
html, body {
  margin: 0;
  padding: 0;
  background-color: #000; 
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

/* Full-page background container with a cover image */
.landing-page {
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/background.jpg'); /* Adjust path as needed */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

/* Semi-transparent overlay for content */
.overlay-box {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

/* Main heading with medieval font (Cinzel) */
.overlay-box h1 {
  font-family: 'Cinzel', serif;
  font-size: 2rem;
  color: #ffe58c;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.8);
  margin-bottom: 1rem;
}

/* Paragraphs/disclaimers with simpler font (Roboto) */
.overlay-box p,
.overlay-box .description,
.overlay-box .terms {
  font-family: 'Roboto', sans-serif;
  color: #eaeaea;
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 1rem;
}

/* Keep buttons stacked on all screen sizes by default */
.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
}

/* Twitch button */
.twitch-button {
  font-family: 'Roboto', sans-serif;
  background-color: #6441a5;
  color: #fff;
  text-decoration: none;
  padding: 12px 25px;
  border-radius: 5px;
  font-weight: 500;
  margin: 0.5rem;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.twitch-button:hover {
  background-color: #7d5abd;
}

/* Discord button */
.discord-button {
  font-family: 'Roboto', sans-serif;
  background-color: #5865F2;
  color: #fff;
  text-decoration: none;
  padding: 12px 25px;
  border-radius: 5px;
  font-weight: 500;
  margin: 0.5rem;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.discord-button:hover {
  background-color: #474cb5;
}

/* Terms disclaimers slightly smaller */
.terms {
  font-size: 0.9rem;
  color: #ccc;
}

/* =================== Media Queries =================== */

/* 1) Very small screens (max-width: 480px) */
@media (max-width: 480px) {
  .overlay-box {
    padding: 20px;
  }

  .overlay-box h1 {
    font-size: 1.6rem;
  }

  .overlay-box p {
    font-size: 0.95rem;
  }

  .terms {
    font-size: 0.85rem;
  }
}

/* 
  2) Removed the min-width: 768px rule that placed buttons side by side. 
     This ensures buttons remain stacked at all widths. 
*/

/* 3) Large desktops (min-width: 1200px) 
   Increase overlay box max-width and slightly larger heading & text 
*/
@media (min-width: 1200px) {
  .overlay-box {
    max-width: 800px;
    padding: 50px;
  }

  .overlay-box h1 {
    font-size: 2.4rem;
  }

  .overlay-box p {
    font-size: 1.05rem;
  }
}
