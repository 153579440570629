/* TimerTab.css */
.timer-tab {
  padding: 20px;
}

.add-timer {
  margin-bottom: 15px;
}

.timer-list {
  list-style: none;
  padding: 0;
}

.timer-list li {
  margin-bottom: 10px;
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
}
.timer-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.timer-actions button {
  margin-right: 10px;
  background-color: #6441a5;
  color: #fff;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
}
.timer-actions button:hover {
  background-color: #7d5abd;
}
